import type { FC } from "react";
import { styled } from "@mui/material";
import Typography from "@mui/material/Typography";

interface BadgeProps {
    text: string;
    color?: string;
}

const StyledBadge = styled(Typography)(({ theme }) => ({
    position: "relative",
    // right: 10,
    // top: 10,
    marginLeft: "4px",
    borderRadius: theme.shape.borderRadius,
    fontSize: ".775rem",
    color: theme.palette.primary.contrastText,
    fontWeight: 600,
    zIndex: 4,
    padding: theme.spacing(0.4, 1)
}));

const Badge: FC<BadgeProps> = ({ text, color = "primary" }) => {
    return <StyledBadge bgcolor={color}>{text}</StyledBadge>;
};

export default Badge;
