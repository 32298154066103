// Axios
import http from "api/handler";

import type { GigWithRequirements, UserProgressInGig } from "types/gigs";

import getEnvVariable from "env";
const API_URL = getEnvVariable("API_URL");

/**
 * Get only ONE Gig by ID
 * @param {string} id ID of the Gig
 * @return {GigWithRequirements} Gig data or error
 */
const get = async (id: string) => {
    try {
        const response = await http.get(
            `${API_URL}/api/gigs/get?id=${id}&relations=["requirements", "targetLanguage"]`
        );
        if (response.status !== 200)
            return { data: null, error: response.data.message };
        return {
            data: response.data.data.gigs[0] as GigWithRequirements,
            error: null
        };
    } catch (err: any) {
        return {
            data: null,
            error: "Something went wrong "
        };
    }
};

interface JOIN_TO_GIG_DATA {
    userId: string;
    id: string;
    location: string;
    userTranslation?: any[];
}
/**
 * @desc Create relation between user and gig. Join to gig.
 * @param {JoinToGigData} data Data includes User and Gig IDs
 * @return {Promise<{ data: null; error: any;} | { data: GigProgress; error: null; }>} Gig progress or error
 */
const join = async (data: JOIN_TO_GIG_DATA) => {
    try {
        const response = await http.post(
            `${API_URL}/api/gigs/addUserToGig`,
            data
        );
        if (response.status !== 200)
            return { data: null, error: response.data.message };
        return {
            data: response.data.data.userToGigNew as UserProgressInGig,
            error: null
        };
    } catch (err: any) {
        return {
            data: null,
            error:
                err.response.data.message ||
                err.message ||
                "Something went wrong "
        };
    }
};

/**
 * @desc Get all Gig Progresses by user ID
 * @param {string} id ID of the User
 * @return {Promise<{ data: null; error: any;} | { data: UserProgressInGig[]; error: null; }>} Gig progresses or error
 * @author
 */
const getAllProgress = async (id: string) => {
    try {
        const response = await http.get(
            `${API_URL}/api/gigs/getUserToGig?id=${id}`
        );
        if (response.status !== 200)
            return { data: null, error: response.data.message };
        return {
            data: response.data.data.gigs as UserProgressInGig[],
            error: null
        };
    } catch (err: any) {
        return {
            data: null,
            error:
                err.response.data.message ||
                err.message ||
                "Something went wrong "
        };
    }
};

const updateGigProgress = async (
    status: string,
    userId: string,
    gigId: string
) => {
    try {
        const response = await http.post(
            `${API_URL}/api/gigs/updateGigStatus`,
            {
                user: userId,
                gig: gigId,
                status
            }
        );
        if (response.status !== 200)
            return { data: null, error: response.data.message };
        return {
            data: response.data.data.gigs as UserProgressInGig[],
            error: null
        };
    } catch (err: any) {
        return {
            data: null,
            error:
                err.response.data.message ||
                err.message ||
                "Something went wrong "
        };
    }
};

/*
 * @desc Get Gig Progress by ID
 * @param {string} id - relation ID
 * @return {AxiosResponse<GigProgress>} - Gig data or @return {error: string}
 */
const getProgress = async (id: string) => {
    try {
        const response = await http.get(
            `${API_URL}/api/gigs/getUserToGigById?id=${id}&relations=["userTranslation", "userTranslation.language", "gig"]`
        );
        if (response.status !== 200) throw new Error(response.data.message);
        return response.data;
    } catch (err: any) {
        return { error: err.response.data.message || err.message };
    }
};

const getUserProgress = async (userId: string, gigId: string) => {
    try {
        const response = await http.get(
            `${API_URL}/api/gigs/getUserToGig?id=${userId}&gig=${gigId}`
        );
        if (response.status !== 200) throw new Error(response.data.message);
        return response.data;
    } catch (err: any) {
        return { error: err.response.data.message || err.message };
    }
};

interface RATE_GIG_DATA {
    id: string;
    gigRate: number;
}
/**
 * Rate a Gig
 * @param {string} id - gig ID
 * @param {number} gigRate - rating in stars (1-5)
 * @return {AxiosResponse<GigProgress>} - Gig data or @return {error: string}
 */
const rate = async (data: RATE_GIG_DATA) => {
    try {
        const response = await http.post(
            `${API_URL}/api/gigs/rateUserToGig`,
            data
        );
        if (response.status !== 200) throw new Error(response.data.message);
        return response.data;
    } catch (err: any) {
        return { error: err.response.data.message || err.message };
    }
};

/*
 * @desc Answer rescreener question of a Gig Progress
 * @param {string} id - relation ID
 * @return {AxiosResponse<GigProgress>} - Gig data or @return {error: string}
 */
const answer = async (id: string) => {
    try {
        const response = await http.post(`${API_URL}/api/gigs/answerReScreen`, {
            id
        });
        if (response.status !== 200) throw new Error(response.data.message);
        return response.data;
    } catch (err: any) {
        return { error: err.response.data.message || err.message };
    }
};

/*
 * @desc Answer rescreener question of a Gig Progress
 * @param {string} id - relation ID
 * @return {AxiosResponse<GigProgress>} - Gig data or @return {error: string}
 */
const sendTranslation = async (data: any) => {
    try {
        const response = await http({
            url: `${API_URL}/api/gigs/createUserTranslation`,
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem(
                    "HIVEMIND_AUTH_TOKEN"
                )}`
            },
            data: {
                id: data.id,
                userTranslation: JSON.stringify(data.userTranslation),
                file: data.userTranslation[0].source
            }
        });
        if (response.status !== 200) throw new Error(response.data.message);
        return response.data;
    } catch (err: any) {
        return { error: err.response.data.message || err.message };
    }
};

const checkQuota = async (userId: string, gigId: string) => {
    try {
        const response = await http.get(
            `${API_URL}/api/gigs/checkQuota?userId=${userId}&gigId=${gigId}`
        );
        if (response.status !== 200) throw new Error(response.data.message);
        return response.data;
    } catch (err: any) {
        return { error: err.response.data.message || err.message };
    }
};

const Gigs = {
    getGig: (id: string) => get(id),
    join: (data: JOIN_TO_GIG_DATA) => join(data),
    getAllProgress: (id: string) => getAllProgress(id),
    getGigProgress: (relationId: string) => getProgress(relationId),
    getUserProgress: (userId: string, gigId: string) =>
        getUserProgress(userId, gigId),
    rate: (data: RATE_GIG_DATA) => rate(data),
    answerReScreen: (id: string) => answer(id),
    sendTranslation: (data: any) => sendTranslation(data),
    updateUserGigProgress: (status: string, userId: string, gigId: string) =>
        updateGigProgress(status, userId, gigId),
    checkQuota
};

export default Gigs;
