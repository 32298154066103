import { useUserContext } from "context/UserContext";
import { HIVEMIND_GIGS, HIVEMIND_GIG_CONFIG } from "constants/gigs";

const useMoreWays = () => {
    const { userData } = useUserContext();

    const userPreference = () => {
        let notSelectedOnboardingType: HIVEMIND_GIG_CONFIG[] = [];
        // eslint-disable-next-line array-callback-return
        HIVEMIND_GIGS.map(type => {
            if (!userData.flowType.includes(type._databaseId)) {
                notSelectedOnboardingType.push(type);
            }
        });
        return notSelectedOnboardingType;
    };

    return {
        userPreference
    };
};

export default useMoreWays;
