import type { HIVEMIND_GIG_CONFIG } from "constants/gigs";
import type { FC } from "react";

import {
    WayCardBody,
    WayCardContainer,
    WayCardHeader,
    WayCardTitle
} from "./styled";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import { getQuestionsByFlowType } from "api";
import Me from "api/Me";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "context/UserContext";
import { useSnackbar } from "notistack";

const WayCard: FC<HIVEMIND_GIG_CONFIG> = ({ color, _databaseId, name }) => {
    const navigator = useNavigate();
    const { userData } = useUserContext();
    const { enqueueSnackbar } = useSnackbar();
    return (
        <WayCardContainer
            onClick={async () => {
                const questions = await getQuestionsByFlowType(_databaseId);

                if (!questions.length) {
                    await Me.addFlowType([...userData.flowType, _databaseId]);
                    await Me.addFlowTypeBasicCompleted([
                        ...userData.flowTypeBasicCompleted,
                        _databaseId
                    ]);
                    enqueueSnackbar(
                        "Congratulations, you unlocked a new way to earn",
                        { variant: "success", autoHideDuration: 2000 }
                    );
                    setTimeout(() => {
                        navigator("/");
                    }, 2000);
                } else {
                    navigator(
                        `/onboarding/basic/type/${_databaseId}?flowType=${_databaseId}`
                    );
                }
            }}>
            <WayCardHeader color={String(color)}>
                <LockRoundedIcon fontSize='medium' sx={{ color: "#FFF" }} />
            </WayCardHeader>
            <WayCardBody>
                <WayCardTitle>{name}</WayCardTitle>
            </WayCardBody>
        </WayCardContainer>
    );
};

export default WayCard;
