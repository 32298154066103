import { FC, ReactElement, useEffect, useState } from "react";
import {
    ContainerProps,
    Box,
    Container,
    Grow,
    IconButton,
    styled,
    Tooltip
} from "@mui/material";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import BrowserUpdatedRoundedIcon from "@mui/icons-material/BrowserUpdatedRounded";
import { Link, useLocation, useNavigate } from "react-router-dom";

const BLACK_LIST = [
    "/homepage",
    "/onboarding",
    "/settings",
    "/chat",
    "/verify/email",
    "/verify/zipcode",
    "/verify/identity",
    "/referal",
    "/progress",
    "/gig/success",
    "/gig/error",
    "/gig/rescreen",
    "/onboarding/how-do-you-want-to-make-extra-money",
    "/onboarding/completed",
    "/settings/payments/cashout/success"
];

const Navbar: FC = (): ReactElement => {
    const [isReadyForInstall, setIsReadyForInstall] = useState(false);

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        window.addEventListener("beforeinstallprompt", event => {
            // Prevent the mini-infobar from appearing on mobile.
            event.preventDefault();
            // Stash the event so it can be triggered later.
            window.deferredPrompt = event;
            // Remove the 'hidden' class from the install button container.
            setIsReadyForInstall(true);
        });
    }, []);

    async function downloadApp() {
        const promptEvent = window.deferredPrompt;
        if (!promptEvent) {
            // The deferred prompt isn't available.
            return;
        }
        // Show the install prompt.
        promptEvent.prompt();
        // Reset the deferred prompt variable, since
        // prompt() can only be called once.
        window.deferredPrompt = null;
        // Hide the install button.
        setIsReadyForInstall(false);
    }

    return (
        <CustomBox maxWidth='lg'>
            {!BLACK_LIST.includes(pathname) && (
                <Grow in={true}>
                    <Box position='absolute' left={8}>
                        <IconButton size='large' onClick={() => navigate(-1)}>
                            <ArrowBackIosNewRoundedIcon
                                sx={{ fill: "#979797" }}
                            />
                        </IconButton>
                    </Box>
                </Grow>
            )}

            <Link to='/'>
                <img
                    width={200}
                    style={{ marginTop: 5 }}
                    src='/images/logo.png'
                    alt='10K Voices Logo Small'
                />
            </Link>

            <Grow in={isReadyForInstall}>
                <Box position='absolute' right={8}>
                    <Tooltip title='Download the App' placement='left'>
                        <IconButton size='large' onClick={downloadApp}>
                            <BrowserUpdatedRoundedIcon
                                sx={{ fill: "#979797" }}
                            />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Grow>
        </CustomBox>
    );
};

const CustomBox = styled(Container)<ContainerProps>(({ theme }) => ({
    width: "100%",
    height: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow:
        theme.palette.mode === "light"
            ? "0px 5px 10px rgba(238, 238, 238, 0.5)"
            : "",
    zIndex: 1000,
    position: "fixed",
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#202020",
    top: 0,
    left: 0,
    maxWidth: "100% !important"
}));

export default Navbar;
