const stringToColor = (string: string) => {
    // hash the string to get a number
    let hash = 10;
    let i;

    // for each character, add the character code multiplied by the index to the hash
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    // create a hex string
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
};

export default stringToColor;
