import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { CardActionArea, Skeleton } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import Badge from "./Badge";

import { useUserContext } from "context/UserContext";

import {
    CardBox,
    CardImageBackground,
    CardName,
    ImageProps,
    LoaderProps
} from "./styled";

const ToStartCard = (props: any): any => {
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const { userData } = useUserContext();

    return (
        <CardActionArea sx={{ borderRadius: "10px" }}>
            <CardBox onClick={() => navigate(props.path)}>
                <LazyLoadImage
                    src={props.image}
                    alt={props.name}
                    {...ImageProps}
                    afterLoad={() => setLoaded(true)}
                />
                {props._databaseId === "verify-your-account-with-id" ||
                props._databaseId === "tell-us-about-you" ||
                props._databaseId === "zip-code" ? (
                    <Badge text='PLEASE COMPLETE' color='#ef535099' />
                ) : null}

                {props._databaseId === "show-us-a-video" && (
                    <>
                        {userData.videoVerified === false &&
                        userData.video !== null ? (
                            <Badge
                                text=' AWAITING VERIFICATION'
                                color='#f57c0099'
                            />
                        ) : (
                            <Badge text='PLEASE COMPLETE' color='#ef535099' />
                        )}
                    </>
                )}

                {props._databaseId === "verify-your-email" && (
                    <>
                        {userData.emailEnabled === false &&
                        userData.emailSentStatus === true ? (
                            <Badge
                                text=' AWAITING VERIFICATION'
                                color='#f57c0099'
                            />
                        ) : (
                            <Badge text='PLEASE COMPLETE' color='#ef535099' />
                        )}
                    </>
                )}

                <CardName>{props.name}</CardName>

                {loaded ? (
                    <CardImageBackground />
                ) : (
                    <Skeleton {...LoaderProps} />
                )}
            </CardBox>
        </CardActionArea>
    );
};

export default ToStartCard;
