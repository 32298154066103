import CTitle from "components/CTitle";
import {
    WayButtonActionArea,
    WayButtonDescription,
    WayButtonStyled,
    WayButtonTitle
} from "components/MoreWays/styled";
import { QuestionIcon } from "./styled";

const NoQuestionsCard = () => (
    <div>
        <CTitle
            title='Question of the day'
            description='Find the right Gigs for you '
        />
        <WayButtonActionArea sx={{ mt: "12px" }}>
            <WayButtonStyled bgcolor='#A060CA'>
                <WayButtonTitle>Great work </WayButtonTitle>
                <WayButtonDescription>
                    Come back tommorow for more questions!
                </WayButtonDescription>
                <QuestionIcon />
            </WayButtonStyled>
        </WayButtonActionArea>
    </div>
);

export default NoQuestionsCard;
