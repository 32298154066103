import type { Gig } from "types/gigs";
import type { FC } from "react";
import { Fragment } from "react";

// Components
import GigCardAmbassadors from "components/GigCardAmbassadors";
import CTitle from "components/CTitle";
import { ResendButton } from "styled-components/Buttons";
import {
    AmbassadorShareListContainer,
    AmbassadorShareListSwiperContainer
} from "./styled";

import Box from "@mui/material/Box";

import NoGigsBox from "./indexNoGigs";

import Typography from "@mui/material/Typography";

// Swiper
// import Swiper core and required modules
import { Navigation, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "../swiper.css";

import { isBrowser } from "react-device-detect";
interface AmbassadorShareListProps {
    title: string;
    buttonText?: string;
    gigs: Gig[];
    description?: string;
    withFlowType: boolean;
}

const AmbassadorsShareList: FC<AmbassadorShareListProps> = props => {
    const { buttonText, gigs } = props;

    if (gigs?.length === 0)
        return (
            <NoGigsBox
                gigs={gigs}
                title='Networker'
                description='Help us recruit for gigs'
                withFlowType={false}
            />
        );

    return (
        <Fragment>
            <AmbassadorShareListContainer>
                <CTitle {...props} />
                {buttonText && (
                    <ResendButton sx={{ width: "auto" }}>
                        {buttonText}
                    </ResendButton>
                )}
            </AmbassadorShareListContainer>
            <Typography
                sx={{
                    width: "100%",
                    textAlign: "left",
                    fontSize: "0.7rem",
                    color: "#cccccc",
                    marginTop: "4px"
                }}
                variant='body2'>
                {gigs?.length} gigs available
            </Typography>
            <AmbassadorShareListSwiperContainer>
                <Swiper
                    // install Swiper modules
                    modules={[Navigation, Scrollbar]}
                    navigation={isBrowser ? true : false}
                    scrollbar={isBrowser ? true : false}
                    slidesPerView={1.5}
                    spaceBetween={16}
                    lazy>
                    {gigs.map((gig: Gig, index: number) => (
                        <Box key={"box" + index}>
                            <SwiperSlide key={"swg" + index}>
                                <GigCardAmbassadors {...gig} />
                            </SwiperSlide>
                        </Box>
                    ))}
                </Swiper>
            </AmbassadorShareListSwiperContainer>
        </Fragment>
    );
};

export default AmbassadorsShareList;
