import type { FC } from "react";
import { styled } from "@mui/material";
import Typography from "@mui/material/Typography";

interface BadgeProps {
    text: string;
    color?: string;
}

const StyledBadge = styled(Typography)(({ theme }) => ({
    position: "absolute",
    right: 10,
    top: 10,
    borderRadius: theme.shape.borderRadius,
    fontSize: ".675rem",
    color: theme.palette.primary.contrastText,
    fontWeight: 600,
    zIndex: 4,
    padding: theme.spacing(0.4, 1)
}));

const Badge: FC<BadgeProps> = ({ text, color = "primary" }) => {
    return <StyledBadge bgcolor={color}>{text}</StyledBadge>;
};

export default Badge;
