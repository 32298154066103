import type { ChangeEvent, SyntheticEvent } from "react";
import type { Question, QuestionOption } from "types/question";
import { PossibleQuestionType } from "types/question/type";

import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useUserContext } from "context/UserContext";

// Api endpoints
import { AnswerQuestion, getOptionsById } from "api";
import { GIG_TYPES } from "constants/gigs";

// languages
import Languages from "api/Languages";
import UserSettings from "api/UserSettings";
// import type { Language } from "types/languages";
import type { Settings } from "types/user/settings";
import { useNavigate } from "react-router-dom";

interface LoadingState {
    general: boolean;
    button: boolean;
}

/**
 * Question management hook
 * @param {Question} question Question object
 */
const useQuestion = (
    question: Question,
    nextQuestion: () => void,
    isVisible: boolean
) => {
    const { enqueueSnackbar } = useSnackbar();
    const { userData, setUserData } = useUserContext();

    const [loading, setLoading] = useState<LoadingState>({
        general: true,
        button: false
    });

    const navigate = useNavigate();

    // Question options
    const [options, setOptions] = useState<QuestionOption[]>([]);
    // Selected options
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]); // For multiple choice
    const [singleSelection, setSingleSelection] = useState<Question["id"]>(""); // For single choice
    const [dateInput, setDateInput] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [disableSelection, setDisableSelection] = useState<boolean>(false); // For single choice

    // language
    // const [languages, setLanguages] = useState<Language[]>([]);
    // const [selectedLanguages, setSelectedLanguages] = useState<Language[]>([]);

    useEffect(() => {
        (async () => {
            if (!isVisible) return;
            // Get options by question ID
            if (question.type !== PossibleQuestionType.TextInput) {
                const options = await getOptionsById(question.id);

                if (options.error)
                    return enqueueSnackbar(options.error, { variant: "error" });

                const orderedOptions = options.data.questions[0].options.sort(
                    (a: QuestionOption, b: QuestionOption) => a.order > b.order
                );

                // const orderedOptions = options.data.questions[0].options;
                setOptions(orderedOptions);
            }
            setLoading({ ...loading, general: false }); // Stop loading
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible, question]);

    /**
     * Toggle the selection of a question option
     * @param checked if the option is already checked
     * @param option Option object
     * @returns Updated array of strings (Id's of options)
     */
    const toggleOptionsSelection = (
        checked: boolean,
        option: QuestionOption
    ) => {
        const nullifySelection = optionNullifies(option);
        const { id } = option;
        if (nullifySelection) {
            if (checked) {
                setDisableSelection(true);
                return setSelectedOptions([id]);
            }
            setSelectedOptions(selectedOptions.filter(option => option !== id));
            setDisableSelection(false);
        }
        if (!disableSelection) {
            if (checked) return setSelectedOptions([...selectedOptions, id]);
            setSelectedOptions(selectedOptions.filter(option => option !== id));
        }
    };

    /**
     * Check if an option disable more selections.
     * @param option Option object
     */
    const optionNullifies = ({ value }: QuestionOption): boolean => {
        const nullify =
            value.includes("None of the above") ||
            value.includes("Prefer not to answer");
        return nullify;
    };

    const isChecked = (option: QuestionOption) =>
        selectedOptions.includes(option.id);

    const handleDateInput = (e: ChangeEvent<HTMLInputElement>) => {
        if (error) setError("");
        const value = e.target.value;
        const isGreaterThanToday = new Date(value) > new Date();
        if (isGreaterThanToday) {
            setError("Date must be before today");
        } else {
            setDateInput(e.target.value);
        }
    };

    /**
     * Update the single selection state for Input questions
     * @param e Input event
     */
    const changeNumericOption = (e: ChangeEvent<HTMLInputElement>) => {
        setSingleSelection(e.target.value);
    };

    /**
     * Update the single selection state for Input questions
     * @param e Input event
     */
    const changeSingleChoice = (e: ChangeEvent<HTMLInputElement>) => {
        setSingleSelection(e.target.value);
    };

    /**
     * Submit the form to send the selected options
     * @param e Form event
     * @returns Answer the question promise response
     */
    const submitAnswers = async (e: SyntheticEvent) => {
        e.preventDefault();
        setLoading({ ...loading, button: true });

        const { id, type } = question;

        let userAnswer = {}; // Object with selected options Id's and user information

        switch (type) {
            // userAnswer for single choice
            case PossibleQuestionType.SingleChoice:
                userAnswer = {
                    question: { id },
                    options: [{ id: singleSelection }],
                    user: { id: userData.id },
                    type: PossibleQuestionType.SingleChoice
                };

                break;
            // userAnswer for single choice
            case PossibleQuestionType.MultipleChoice:
                userAnswer = {
                    question: { id },
                    options: selectedOptions.map(option => ({ id: option })),
                    user: { id: userData.id },
                    type: PossibleQuestionType.MultipleChoice
                };
                break;
            // userAnswer for single choice
            case PossibleQuestionType.TextInput:
                userAnswer = {
                    question: { id },
                    value: Number(singleSelection),
                    user: { id: userData.id },
                    type: PossibleQuestionType.TextInput
                };
                break;
            case PossibleQuestionType.DateInput:
                userAnswer = {
                    question: { id },
                    value: dateInput,
                    user: { id: userData.id },
                    type: PossibleQuestionType.DateInput
                };
                break;
            default:
                enqueueSnackbar("There was an error", { variant: "error" });
                break;
        }

        if (question.value === "What languages do you speak fluently?") {
            const arrQuestions = [question];
            const arrAnsweredOptions: any[] = [];
            const arrAnsweredValues: any[] = [];
            const arrAnsweredLangToSettings: any[] = [];

            // get questions
            arrQuestions.map((o: any) => {
                arrAnsweredOptions.push(o.options);
                return arrAnsweredOptions;
            });

            // get options
            arrAnsweredOptions[0].map((v: any) => {
                selectedOptions.map((z: any) => {
                    if (z === v.id) {
                        arrAnsweredValues.push(v.value);
                    }
                    return z;
                });
                return v;
            });

            // gel all languages
            const res_lang = await Languages.getAll();
            if (res_lang.error) return navigate("/homepage");

            if (res_lang) {
                res_lang?.data?.map((lang: any) => {
                    arrAnsweredValues.map((ao: any) => {
                        if (lang.name === ao) {
                            arrAnsweredLangToSettings.push(lang);
                        }
                        return ao;
                    });
                    return lang;
                });
            }

            setLoading({ ...loading, general: false });
            // update setting with languages
            const settings = {
                ...userData.settings,
                languages: arrAnsweredLangToSettings
            };

            const res = await UserSettings.update(settings);
            if (res.error) {
                enqueueSnackbar(res.error, { variant: "error" });
                return setLoading({ ...loading, button: false });
            }

            setUserData({ ...userData, settings: res.data as Settings });
            enqueueSnackbar("Languages preferences successfully updated", {
                variant: "info"
            });
        }

        // Answer Question TO DO
        const res = await AnswerQuestion(userAnswer);
        if (res.error) return enqueueSnackbar(res.error, { variant: "error" });

        setLoading({ ...loading, button: false }); // Stop loading

        // #TODO: Delete card and pass to next one
        nextQuestion();
    };

    const getQuestionType = (raw: string) => {
        if (raw === GIG_TYPES.COUNCIL_MEMBERS) return "Reviewer";
        if (raw === GIG_TYPES.COUNCIL_MEMBERS_EXPRESS)
            return "Reviewer Express";
        if (raw === GIG_TYPES.AMBASSADORS) return "Networker";
        if (raw === GIG_TYPES.GREAT_CONNECTORS) return "Interceptor";
        if (raw === GIG_TYPES.COMMUNITY_ARCHITECTS) return "Builder";
        if (raw === GIG_TYPES.CURIOUS_CONSUMERS) return "Shopper";
        if (raw === GIG_TYPES.GLOBAL_COMMUNICATORS) return "Translator";
        if (raw === "question-of-day") return "Question of the day";
        return "General";
    };

    return {
        loading,
        options,
        error,
        submitAnswers,
        getQuestionType,
        isChecked,
        changeNumericOption,
        changeSingleChoice,
        handleDateInput,
        toggleOptionsSelection
    };
};

export default useQuestion;
