import type { Gig } from "types/gigs";
import type { FC } from "react";
import { Fragment } from "react";

// Components
import CTitle from "components/CTitle";
import { AmbassadorShareListContainer } from "./styled";

import Alert from "@mui/material/Alert";

interface AmbassadorShareListProps {
    title: string;
    buttonText?: string;
    gigs: Gig[];
    description?: string;
    withFlowType: boolean;
}

const AmbassadorsShareList: FC<AmbassadorShareListProps> = props => {
    return (
        <Fragment>
            <AmbassadorShareListContainer>
                <CTitle {...props} />
            </AmbassadorShareListContainer>

            <Alert
                sx={{ width: "100%", marginBottom: "10px", marginTop: "20px" }}
                severity='info'>
                There are no gigs that match your preferences.
            </Alert>
        </Fragment>
    );
};

export default AmbassadorsShareList;
