import axios from "axios";
import getEnvVariable from "env";
/* import getEnvVariable from "env"; */
/* const API_URL = getEnvVariable("API_URL"); */

const axiosDefaults = {};
const http = axios.create(axiosDefaults);

export const generateApiConnection = () => getEnvVariable("API_URL");

/**
 * @author Valentín Galfré
 * Axios interceptor to get the access token from the localStorage
 * And only allow requestion from the API_URL
 * (I recommend to start using Cookies instead)
 * @returns {AxiosInstace} Instance
 */
http.interceptors.request.use(
    config => {
        /* const { origin } = new URL(String(config.url));
      const allowedOrigins = [API_URL]; */
        let token = localStorage.getItem("HIVEMIND_AUTH_TOKEN");

        if (!token) {
            const userInfo = JSON.parse(
                localStorage.getItem("userInfo") as string
            );
            if (userInfo) token = userInfo.token;
        }
        /* if (allowedOrigins.includes(origin)) */
        config.headers!.authorization = `Bearer ${token}`;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

/**
 * @author Valentín Galfré
 * Axios interceptor to redirect the user to the login page
 * if any request throw a 403 error
 * @returns {AxiosInstace} Instance
 */
http.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 403) {
            localStorage.removeItem("userInfo");
            return (window.location.href = "/start");
        }
    }
);

export default http;
