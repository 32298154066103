import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";

import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";

import { styled } from "@mui/material";
import { QuestionMarkRounded } from "@mui/icons-material";

export const QuestionOfTheDay = styled(Typography)(({ theme }) => ({
    fontSize: ".935rem",
    color: theme.palette.primary.main,
    lineHeight: "136%",
    letterSpacing: "-.36px",
    fontWeight: "600"
}));

export const QuestionOfTheDayContainer = styled(Box)(({ theme }) => ({
    position: "relative",
    padding: "12px",
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "8px"
}));

export const QuestionOfTheDayActionArea = styled(CardActionArea)({
    borderRadius: "8px",
    margin: "12px 0 15px 0"
});

export const QuestionOfTheDayIcon = styled(CalendarMonthRoundedIcon)({
    position: "absolute",
    fontSize: "1rem",
    color: "#A060CA",
    right: 14,
    bottom: 12
});

export const ModalBackdropProps = {
    sx: { backdropFilter: "blur(3px)", backgroundColor: "#66666687" }
};

export const QuestionIcon = styled(QuestionMarkRounded)({
    position: "absolute",
    fontSize: "1rem",
    color: "#FFF",
    right: 12,
    bottom: 12
});
