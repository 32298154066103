import { styled } from "@mui/material";
import Typography, { TypographyProps } from "@mui/material/Typography";
import Box, { BoxProps } from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";

import { CustomLoadingButton } from "styled-components/Buttons";
import { LoadingButtonProps } from "@mui/lab";

export const StyledTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
    color: theme.palette.text.secondary,
    letterSpacing: "-0.36px",
    marginBottom: "14px"
}));

export const CardWrapper = styled("ul")(({ theme }) => ({
    margin: 0,
    padding: 0,
    position: "relative",
    width: "100%",
    height: "auto"
}));

export const QuestionTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    fontSize: "1.3rem",
    letterSpacing: "-.5px",
    lineHeight: "120%",
    color: theme.palette.text.secondary
}));

export const QuestionTopTags = styled(Typography)(({ theme }) => ({
    fontWeight: 500,
    marginTop: "10px",
    fontSize: "1rem",
    color: theme.palette.primary.main,
    marginBottom: "15px",
    maxWidth: "220px"
}));

export const QuestionDescription = styled(Typography)(({ theme }) => ({
    fontWeight: 500,
    marginTop: "10px",
    fontSize: ".8rem",
    color: "#777"
}));

export const AnswerContainer = styled(Box)<BoxProps>(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "8px",
    paddingBottom: "4px",
    borderBottom: `1px solid ${theme.palette.divider}`
}));

export const AnswerTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 800,
    fontSize: "1.3rem",
    letterSpacing: "-.5px",
    lineHeight: "120%",
    color: theme.palette.secondary.main
}));

export const AnswerText = styled(Typography)(({ theme }) => ({
    fontWeight: 500,
    fontSize: ".9rem",
    letterSpacing: "-.36px",
    lineHeight: "120%",
    color: "#777"
}));

export const AnswerButton = styled(CustomLoadingButton)<LoadingButtonProps>(
    ({ theme }) => ({
        marginTop: "18px",
        maxWidth: "160px"
    })
);

// Question
export const QuestionAnswerGroup = styled(FormGroup)(({ theme }) => ({
    marginTop: "1.5rem",
    display: "flex",
    flexDirection: "column"
}));

export const QuestionForm = styled("form")({
    marginTop: "12px"
});
