import { styled } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";

export const GigListContainer = styled(Box)<BoxProps>({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
});

export const GigListSwiperContainer = styled(Box)<BoxProps>({
    marginTop: "18px",
    marginBottom: "18px",
    // width: "calc(100% + 20px)"
    width: "100%"
});
