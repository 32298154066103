import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

export const NavigationBox = styled(Box)(({ theme }) => ({
    position: "fixed",
    left: 0,
    bottom: 0,
    zIndex: 2200,
    width: "100%",
    height: "auto",
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#202020",
    boxShadow:
        theme.palette.mode === "light"
            ? "0px -5px 10px rgba(238, 238, 238, 0.5)"
            : "none",
    padding: "0 24px"
}));

export const StyledBottomNavigation = styled(BottomNavigation)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#202020"
}));

export const StyledBottomNavigationAction = styled(BottomNavigationAction)(
    ({ theme }) => ({
        fontWeight: 600
    })
);
