import { Fragment, FC } from "react";
import { useParams } from "react-router-dom";
import CCheckbox from "components/CCheckbox";
import {
    QuestionTopTags,
    QuestionTitle,
    QuestionDescription,
    AnswerContainer,
    AnswerText,
    AnswerButton,
    QuestionForm
} from "./styled";
import { Question as QuestionType, QuestionOption } from "types/question";
import useQuestion from "./useQuestion";
import CRadioButton from "components/CRadioButton";
import { Box, RadioGroup, Typography } from "@mui/material";

// Loading skelenton
import QuestionSkeleton from "./skeleton";
import { PossibleQuestionType } from "types/question/type";
import CTextField from "components/CTextField";

interface QuestionProps {
    question: QuestionType;
    isVisible: boolean;
    nextQuestion: () => void;
}

const Question: FC<QuestionProps> = ({ question, isVisible, nextQuestion }) => {
    const {
        loading,
        options,
        error,
        submitAnswers,
        isChecked,
        changeSingleChoice,
        handleDateInput,
        toggleOptionsSelection,
        getQuestionType,
        changeNumericOption
    } = useQuestion(question, nextQuestion, isVisible);
    const { type } = useParams();

    if (!isVisible) return <Fragment></Fragment>;
    if (loading.general) return <QuestionSkeleton />;

    return (
        <Fragment>
            {type && (
                <QuestionTopTags>
                    #{getQuestionType(type)} Basic
                </QuestionTopTags>
            )}

            <QuestionTitle>
                {/* Which type of studies would you be interested in? */}
                {question.value}
            </QuestionTitle>
            {options.length > 0 && (
                <QuestionDescription>
                    <em>{options.length} Answers</em>
                </QuestionDescription>
            )}
            {options.length === 0 && (
                <QuestionDescription>
                    We use this information to give you the best experience
                </QuestionDescription>
            )}

            <QuestionForm onSubmit={submitAnswers}>
                {question.type === PossibleQuestionType.SingleChoice && (
                    <RadioGroup
                        name='options'
                        onChange={e => changeSingleChoice(e)}>
                        {options.map((option: QuestionOption) => (
                            <AnswerContainer key={option.id}>
                                <AnswerText>{option.value}</AnswerText>
                                <CRadioButton
                                    required
                                    key={option.id}
                                    value={option.id}
                                />
                            </AnswerContainer>
                        ))}
                    </RadioGroup>
                )}

                {question.type === PossibleQuestionType.MultipleChoice && (
                    <Fragment>
                        {options.map((option: QuestionOption) => (
                            <AnswerContainer key={option.id}>
                                <AnswerText>{option.value}</AnswerText>
                                <CCheckbox
                                    key={option.id}
                                    value={option.id}
                                    checked={isChecked(option)}
                                    onChange={e =>
                                        toggleOptionsSelection(
                                            e.target.checked,
                                            option
                                        )
                                    }
                                />
                            </AnswerContainer>
                        ))}
                    </Fragment>
                )}

                {question.type === PossibleQuestionType.TextInput && (
                    <CTextField
                        type='number'
                        onKeyDown={e =>
                            ["e", "E", "+", "-"].includes(e.key) &&
                            e.preventDefault()
                        }
                        onChange={(e: any) => changeNumericOption(e)}
                    />
                )}

                {question.type === PossibleQuestionType.DateInput && (
                    <Box width='100%'>
                        <CTextField
                            required
                            fullWidth
                            type='date'
                            onChange={(e: any) => handleDateInput(e)}
                        />
                    </Box>
                )}

                {error && (
                    <Typography mt={2} color='crimson' variant='body1'>
                        {error}
                    </Typography>
                )}
                <AnswerButton
                    disabled={!!error}
                    loading={loading.button}
                    type='submit'>
                    Answer
                </AnswerButton>
            </QuestionForm>
        </Fragment>
    );
};

export default Question;
