import type { FC } from "react";
import useMoreWays from "./useMoreWays";
import { Box, Divider } from "@mui/material";

// Swiper
// import Swiper core and required modules
import { Navigation, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "../swiper.css";

import { StyledTitle, SwiperContainer } from "./styled";

// Components
import WayCard from "./WayCard";
import WayButton from "./WayButton";
import CTitle from "components/CTitle";

import { isBrowser } from "react-device-detect";
interface MoreWaysProps {
    withCards?: boolean;
}

const MoreWays: FC<MoreWaysProps> = ({ withCards }) => {
    if (withCards) return <VariantWithCards />;
    if (!withCards) return <VariantWithoutCards />;
    return null;
};

export default MoreWays;

const VariantWithCards = () => {
    const { userPreference } = useMoreWays();

    if (userPreference().length === 0)
        return <Box display='flex' flexDirection='column'></Box>;

    return (
        <Box mt='26px'>
            <Divider sx={{ margin: "30px 0 26px 0" }} />
            <StyledTitle>Unlock More Ways to Earn</StyledTitle>
            <SwiperContainer>
                <Swiper
                    // install Swiper modules
                    modules={[Navigation, Scrollbar]}
                    navigation={isBrowser ? true : false}
                    scrollbar={isBrowser ? true : false}
                    lazy
                    slidesPerView={3.2}
                    spaceBetween={9}>
                    {userPreference().map((type, index) => (
                        <SwiperSlide key={index}>
                            <WayCard {...type} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </SwiperContainer>
        </Box>
    );
};

const VariantWithoutCards = () => {
    const { userPreference } = useMoreWays();

    if (userPreference().length === 0) return null;

    return (
        <>
            <Box display='flex' flexDirection='column'>
                <Divider sx={{ margin: "30px 0 26px 0" }} />

                <CTitle
                    title='More ways to earn'
                    description='Unlock these addition ways to earn !'
                />

                <Box display='flex' columnGap='10px' mt='14px'>
                    <SwiperContainer>
                        <Swiper
                            // install Swiper modules
                            modules={[Navigation, Scrollbar]}
                            navigation={isBrowser ? true : false}
                            scrollbar={isBrowser ? true : false}
                            lazy
                            slidesPerView={1.3}
                            spaceBetween={14}
                            style={{ height: "100%" }}>
                            {userPreference().map((way, index) => (
                                <SwiperSlide key={index}>
                                    <WayButton {...way} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </SwiperContainer>
                </Box>
            </Box>
        </>
    );
};
