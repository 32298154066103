import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { CardActionArea, Divider, Skeleton, Typography } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import Box from "@mui/material/Box";
import Badge from "./Badge";

import { HIVEMIND_GIGS, HIVEMIND_GIG_CONFIG } from "constants/gigs";

import {
    CardBox,
    CardImageBackground,
    ImageProps,
    CardName,
    LoaderProps
} from "./styled";
import { useUserContext } from "context/UserContext";
import Me from "api/Me";
import CTitle from "components/CTitle";

const GigOnboardingCard = ({
    flowType
}: {
    flowType: HIVEMIND_GIG_CONFIG["_databaseId"];
}) => {
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const { userData } = useUserContext();
    const [isAnswered, setIsAnswered] = useState(false);

    const statusBasicCompleted =
        userData.flowTypeBasicCompleted?.includes(flowType);

    const type = HIVEMIND_GIGS.find(type => flowType === type._databaseId)!;

    useEffect(() => {
        const checkFlowTypeStatus = async () => {
            if (!statusBasicCompleted) {
                const questions = await Me.getUnansweredQuestions([flowType]);
                if (!questions.data?.length) {
                    setIsAnswered(true);
                    Me.addFlowTypeBasicCompleted([
                        ...userData.flowTypeBasicCompleted,
                        flowType
                    ]);
                }
            }
        };
        checkFlowTypeStatus();
    }, [flowType, userData.flowTypeBasicCompleted, statusBasicCompleted]);

    if (!statusBasicCompleted && !isAnswered) {
        return (
            <Box display='flex' flexDirection='column' gap={2}>
                <Divider sx={{ margin: "30px 0 26px 0" }} />
                <CTitle title={type.name} description={type.shortDescription} />
                <CardActionArea sx={{ borderRadius: "10px" }}>
                    <Box>
                        <CardBox
                            onClick={() =>
                                navigate(
                                    `/onboarding/basic/type/${type._databaseId}?flowType=${type._databaseId}`
                                )
                            }>
                            <LazyLoadImage
                                src={type.image}
                                alt={type.name}
                                {...ImageProps}
                                afterLoad={() => setLoaded(true)}
                            />
                            <CardName>
                                {type.name} Basic <br />
                                <Typography variant='body2' component='span'>
                                    Complete first to participate.
                                </Typography>
                            </CardName>
                            <Badge
                                text='ONBOARDING!'
                                color={type.color + "99"}
                            />
                            {loaded ? (
                                <CardImageBackground />
                            ) : (
                                <Skeleton {...LoaderProps} />
                            )}
                        </CardBox>
                    </Box>
                </CardActionArea>
            </Box>
        );
    } else return null;
};

export default GigOnboardingCard;
