import type { Language } from "types/languages";
// Axios
import axios from "axios";

import { getUserInfo } from "api";
import getEnvVariable from "env";

const apiUrl = getEnvVariable("API_URL");
const { token } = getUserInfo(); // Token

// Axios instance
const instance = axios.create({
    baseURL: apiUrl,
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
    }
});

/**
 * @desc Get 10K Voices languages
 * @return {Promise<{ data: null; error: any;} | { data: Language[]; error: null; }>} Array of languages or error
 */
const getAll = async (): Promise<
    { data: null; error: any } | { data: Language[]; error: null }
> => {
    try {
        const response = await instance.get(
            `/api/language/getAll?typelanguage`
        );
        if (response.status !== 200)
            return { data: null, error: response.data.message };
        return {
            data: response.data.data.language as Language[],
            error: null
        };
    } catch (err: any) {
        return {
            data: null,
            error:
                err.response.data.message ||
                err.message ||
                "Something went wrong "
        };
    }
};

const Languages = {
    getAll: () => getAll()
};

export default Languages;
