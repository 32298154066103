// Get the search parameters from the URL
const getSearchParams = () => {
    const params = new URLSearchParams(document.location.search);
    let result: any = {};

    // Iterate over all the parameters
    for (const [key, value] of params as any) {
        result[key] = value;
    }
    return result;
};

export default getSearchParams;
