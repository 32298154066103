import { Suspense, useEffect, useState } from "react";
import Navbar from "layouts/Header";
import { Container } from "@mui/material";
import { GetMe, getUserInfo } from "api";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Loader } from "styled-components/Miscelaneous";
import { useSnackbar } from "notistack";
import { useUserContext } from "context/UserContext";
import UserSettings from "api/UserSettings";

const OnBoardingLayout = () => {
    const { pathname } = useLocation();
    const { token, didAnswer, id } = getUserInfo();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { userData, setUserData } = useUserContext();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setLoading(true);

        // get user info
        Promise.all([GetMe(token), UserSettings.get(id)])
            .then(([me, res]) => {
                setUserData({
                    ...me.data.user,
                    settings: res.data
                });
            })
            .catch(error => {
                enqueueSnackbar(error, {
                    variant: "error"
                });
            })
            .finally(() => {
                setLoading(false);
            });

        if (!token) {
            navigate("/start");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, navigate, didAnswer, pathname]);

    if (loading === true) return <Loader />;

    // if not step way to win
    if (
        pathname.includes("/onboarding/how-do-you-want-to-make-extra-money") &&
        !pathname.includes("/onboarding/completed")
    ) {
        if (userData.answeredOnboarding === true)
            return <Navigate to='/homepage' replace />;
    }

    return (
        <Container maxWidth='xs' sx={{ padding: 0 }}>
            <Navbar />
            <Suspense fallback={<Loader />}>
                <Outlet />
            </Suspense>
        </Container>
    );
};

export default OnBoardingLayout;
