import { Container } from "@mui/material";
import { useUserContext } from "context/UserContext";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Loader } from "styled-components/Miscelaneous";
import Navbar from "./Header";
import Navigation from "./Navigation";
import { USER_STATUS } from "types/user";

const SuspenseLayout = () => {
    const { userData } = useUserContext();

    return (
        <Container component='main' maxWidth='xs' sx={{ padding: 0 }}>
            <Navbar />
            <Suspense fallback={<Loader />}>
                <Outlet />
            </Suspense>
            {userData.status !== USER_STATUS.OUTLOGGED && <Navigation />}
        </Container>
    );
};

export default SuspenseLayout;
