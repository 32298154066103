// Possible types for a question

// Onboarding => Question
// External Survey => Question
// More types ...
export enum PossibleFlowType {
    Onboarding = "onboarding",
    Hidden = "hide",
    ExternalSurvey = "external-survey",
    InPersonStudy = "in-person",
    Recruiter = "recruiter",
    Networker = "networker",
    Interceptor = "interceptor",
    MysteryShopper = "mystery-shopper",
    Translator = "translator"
}

// Posible types for a question
export enum PossibleQuestionType {
    TextInput = "input",
    MultipleChoice = "checkbox",
    SingleChoice = "radio",
    DateInput = "date"
}
