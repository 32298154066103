import { FC, ReactElement, SyntheticEvent, useMemo } from "react";
import { useEffect, useState } from "react";

import { Badge, Container } from "@mui/material";

// Icons
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import ChatRoundedIcon from "@mui/icons-material/ChatRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";

import { useLocation, useNavigate } from "react-router-dom";
import {
    NavigationBox,
    StyledBottomNavigation,
    StyledBottomNavigationAction
} from "./styled";
import { useConversationContext } from "context/conversations/ConversationsContext";

const paths = ["/homepage", "/progress", "/chat", "/settings"];

export const Navigation: FC<any> = (): ReactElement => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { pendingMessages } = useConversationContext();

    const [value, setValue] = useState<string>("");

    const handleChange = (_: SyntheticEvent, newValue: string) => {
        setValue(newValue);
        navigate(`/${newValue}`);
    };

    useEffect(() => {
        if (paths.includes(pathname)) setValue(pathname.replace("/", ""));
    }, [pathname]);

    const pendingMessagesNumber = useMemo(
        () =>
            Object.keys(pendingMessages).reduce((accum, conversation) => {
                return accum + pendingMessages[conversation];
            }, 0),
        [pendingMessages]
    );

    return (
        <NavigationBox component='nav'>
            <Container maxWidth='xs' sx={{ padding: 0 }}>
                <StyledBottomNavigation value={value} onChange={handleChange}>
                    <StyledBottomNavigationAction
                        label='Home'
                        value='homepage'
                        icon={<HomeRoundedIcon />}
                    />
                    <StyledBottomNavigationAction
                        label='Gigs'
                        value='progress'
                        icon={<FormatListBulletedRoundedIcon />}
                    />

                    <StyledBottomNavigationAction
                        label='Chats'
                        value='chat'
                        icon={
                            <Badge
                                badgeContent={pendingMessagesNumber}
                                showZero={false}
                                color='primary'>
                                <ChatRoundedIcon />
                            </Badge>
                        }
                    />

                    <StyledBottomNavigationAction
                        label='Settings'
                        value='settings'
                        icon={<SettingsRoundedIcon />}
                    />
                </StyledBottomNavigation>
            </Container>
        </NavigationBox>
    );
};

export default Navigation;
