const stringToAvatar = (name: string) => {
    // Override the default Material UI Avatar styles
    return {
        sx: {
            bgcolor: "#FFF",
            color: "#000",
            width: 56,
            height: 56
        },
        // Use the first letter of the name as the avatar
        children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
    };
};

export default stringToAvatar;
