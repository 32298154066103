import { Fragment } from "react";
import { alpha, styled } from "@mui/material";
import Skeleton, { SkeletonProps } from "@mui/material/Skeleton";

const QuestionSkeleton = () => {
    return (
        <Fragment>
            <CSkeleton variant='rectangular' width={240} height={18} />
            <CSkeleton variant='rectangular' width='100%' height={20} mt={20} />
            <CSkeleton
                variant='rectangular'
                width='100%'
                height={20}
                mt={5}
                color='#C5C5C5B8'
            />
            <CSkeleton variant='rectangular' width='90%' height={20} mt={5} />
            <CSkeleton variant='rectangular' width={100} height={18} mt={10} />

            <CSkeleton
                variant='rectangular'
                width='100%'
                height={46}
                mt={20}
                color='#E4E4E4B8'
            />
            <CSkeleton
                variant='rectangular'
                width='100%'
                height={46}
                mt={10}
                color='#E4E4E4B2'
            />
            <CSkeleton
                variant='rectangular'
                width='100%'
                height={46}
                mt={10}
                color='#E4E4E4B0'
            />
            <CSkeleton variant='rectangular' width={160} height={50} mt={20} />
        </Fragment>
    );
};

export default QuestionSkeleton;

export const CSkeleton = styled(Skeleton)<
    SkeletonProps & { mt?: number; color?: string }
>(({ color = "#CDCDCDB8", mt = 0, theme }) => ({
    borderRadius: 5,
    marginTop: `${mt}px`,
    backgroundColor: theme.palette.mode === "dark" ? alpha(color, 0.1) : color
}));
