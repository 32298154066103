import type { Gig } from "types/gigs";
import type { FC } from "react";
import { Fragment } from "react";

// Components
import CTitle from "components/CTitle";
import { GigListContainer } from "./styled";

import Alert from "@mui/material/Alert";

interface GigListProps {
    title: string;
    buttonText?: string;
    gigs: Gig[];
    description?: string;
    withFlowType: boolean;
}

const GigsList: FC<GigListProps> = props => {
    return (
        <Fragment>
            <GigListContainer>
                <CTitle {...props} />
            </GigListContainer>

            <Alert
                sx={{ width: "100%", marginBottom: "10px", marginTop: "20px" }}
                severity='info'>
                There are no gigs that match your preferences.
            </Alert>
        </Fragment>
    );
};

export default GigsList;
