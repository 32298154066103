export enum START_TYPES {
    START_TYPES_EMAIL = "verify-your-email",
    START_TYPES_ACCOUNT_ID = "verify-your-account-with-id",
    START_TYPES_VIDEO = "show-us-a-video",
    START_TYPES_ZIP_CODE = "zip-code"
}

export enum START_TYPES_IMG {
    START_TYPES_EMAIL = "/images/to_start/verify-your-email.png",
    START_TYPES_ACCOUNT_ID = "/images/to_start/verify-your-account-with-id.png",
    START_TYPES_VIDEO = "/images/to_start/show-us-a-video.png",
    START_TYPES_ZIP_CODE = "/images/to_start/verify-your-zip-code.png"
}

export enum START_TYPES_PATH {
    START_TYPES_EMAIL = "/verify/email",
    START_TYPES_ACCOUNT_ID = "/verify/identity",
    START_TYPES_VIDEO = "/verify/video",
    START_TYPES_ZIP_CODE = "/verify/zipcode"
}

export interface START_TYPES_CONFIG {
    _id?: string;
    _databaseId: START_TYPES;
    name: string;
    shortDescription: string;
    longDescription: string;
    image?: string;
    color?: string;
    completed?: boolean;
    path?: string;
}

export const START_TYPES_LIST: START_TYPES_CONFIG[] = [
    {
        _databaseId: START_TYPES.START_TYPES_EMAIL,
        name: `Verify your email`,
        shortDescription: `Enter to email verification screen.`,
        longDescription: `Enter to email verification screen`,
        color: "#EAA572",
        image: START_TYPES_IMG.START_TYPES_EMAIL,
        completed: false,
        path: START_TYPES_PATH.START_TYPES_EMAIL
    },
    {
        _databaseId: START_TYPES.START_TYPES_ACCOUNT_ID,
        name: `Verify your ID`,
        shortDescription: `Enter to verify your account with ID screen.`,
        longDescription: `Enter to verify your account with ID screen.`,
        color: "#EAA572",
        image: START_TYPES_IMG.START_TYPES_ACCOUNT_ID,
        completed: false,
        path: START_TYPES_PATH.START_TYPES_ACCOUNT_ID
    },
    {
        _databaseId: START_TYPES.START_TYPES_ZIP_CODE,
        name: `Verify your zip code`,
        shortDescription: `Enter to verify your zip code screen.`,
        longDescription: `Enter to verify your zip code screen.`,
        color: "#EAA572",
        image: START_TYPES_IMG.START_TYPES_ZIP_CODE,
        completed: false,
        path: START_TYPES_PATH.START_TYPES_ZIP_CODE
    },
    {
        _databaseId: START_TYPES.START_TYPES_VIDEO,
        name: `Show us a video`,
        shortDescription: `Enter to show us a video screen.`,
        longDescription: `Enter to show us a video screen`,
        color: "#EAA572",
        image: START_TYPES_IMG.START_TYPES_VIDEO,
        completed: false,
        path: START_TYPES_PATH.START_TYPES_VIDEO
    }
];
