import type { Gig } from "types/gigs";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
    Box,
    CardActionArea,
    Skeleton,
    Typography,
    useTheme
} from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Badge from "./Badge";

import {
    CardBox,
    CardBoxText,
    CardImageBackground,
    CardName,
    ImageProps,
    LoaderProps
} from "./styled";

import getEnvVariable from "env";

import { GIG_TYPES } from "constants/gigs";

const GigCardAmbassadors = ({
    name,
    image,
    id,
    participants,
    participantsLeft,
    priceToRecruit,
    flowType,
    payment
}: Gig) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const imageHostUrl = getEnvVariable("IMAGE_HOST_URL");

    const participantsPercentage = participants * (10 / 100);
    const particiapantsDiference = participants - participantsLeft;
    const isNew =
        particiapantsDiference <= participantsPercentage ? true : false;
    const isEnding =
        participantsLeft <= Math.ceil(participantsPercentage * 2)
            ? true
            : false;

    const getQuestionType = (raw: string) => {
        if (raw === GIG_TYPES.COUNCIL_MEMBERS) return "Reviewer";
        if (raw === GIG_TYPES.COUNCIL_MEMBERS_EXPRESS)
            return "Reviewer express";
        if (raw === GIG_TYPES.AMBASSADORS) return "Networker";
        if (raw === GIG_TYPES.GREAT_CONNECTORS) return "Interceptor";
        if (raw === GIG_TYPES.COMMUNITY_ARCHITECTS) return "Builder";
        if (raw === GIG_TYPES.CURIOUS_CONSUMERS) return "Shopper";
        if (raw === GIG_TYPES.GLOBAL_COMMUNICATORS) return "Translator";
    };

    return (
        <CardActionArea
            sx={{ borderRadius: "10px", marginBottom: "10px" }}
            onClick={() => navigate(`/gig/${id}/recruit/ambassadors`)}>
            <CardBox>
                <LazyLoadImage
                    src={imageHostUrl + image}
                    alt={name}
                    {...ImageProps}
                    afterLoad={() => setLoaded(true)}
                />

                <Box
                    sx={{
                        display: "flex",
                        position: "absolute",
                        right: "10px",
                        top: "10px",
                        width: "100%",
                        justifyContent: "flex-end",
                        alignContent: "center",
                        alignItems: "center"
                    }}>
                    {isNew && !isEnding && (
                        <Badge text='NEW!' color='#a060cace' />
                    )}
                    {isEnding && (
                        <Badge
                            text={`${participantsLeft} Left`}
                            color='#ca6060ce'
                        />
                    )}
                    <Badge
                        text={(function () {
                            if (
                                flowType === "ambassadors" &&
                                priceToRecruit !== "0.00"
                            )
                                return `$${priceToRecruit}`;
                            else return `$${payment}`;
                        })()}
                        color='#00000099'
                    />
                </Box>

                {loaded ? (
                    <CardImageBackground />
                ) : (
                    <Skeleton {...LoaderProps} />
                )}
            </CardBox>
            <CardBoxText>
                <Typography
                    sx={{
                        width: "100%",
                        textAlign: "right",
                        fontSize: "0.6rem",
                        color: "#cccccc",
                        marginTop: "0",
                        position: "absolute",
                        top: "5px",
                        right: "8px"
                    }}
                    variant='body2'
                    component='span'>
                    {flowType ? getQuestionType(flowType) : null}
                </Typography>
                <CardName>
                    {participantsLeft} Spots Left
                    <br />
                    <Typography
                        variant='body2'
                        component='span'
                        sx={{
                            fontWeight: 500,
                            letterSpacing: "-.36px",
                            lineHeight: "136%",
                            textAlign: "left",
                            maxWidth: "192px",
                            color:
                                theme.palette.mode === "dark" ? "#FFF" : "#000"
                        }}>
                        {name}
                    </Typography>
                </CardName>
            </CardBoxText>
        </CardActionArea>
    );
};

export default GigCardAmbassadors;
