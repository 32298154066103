import { CardActionArea, styled } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import Typography, { TypographyProps } from "@mui/material/Typography";

import LockRoundedIcon from "@mui/icons-material/LockRounded";
import { Link, LinkProps } from "react-router-dom";

export const StyledTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
    color: theme.palette.text.secondary,
    letterSpacing: "-0.36px",
    marginBottom: "14px"
}));

export const WayCardContainer = styled(Box)<BoxProps>(({ theme }) => ({
    // boxShadow: theme.shadows[1],
    borderRadius: "5px",
    height: "100%",
    cursor: "pointer",
    border: "1px solid rgba(0, 0, 0, 0.12)"
}));

export const WayCardHeader = styled(Box)<{ color: string }>(({ color }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px 5px 0 0",
    height: 52,
    backgroundColor: color
}));

export const WayCardBody = styled(Box)<BoxProps>(({ theme }) => ({
    padding: "12px",
    height: "22px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px"
}));

export const WayCardTitle = styled(Typography)<TypographyProps>(
    ({ theme }) => ({
        color: theme.palette.text.secondary,
        fontSize: ".7rem",
        textAlign: "center"
    })
);

export const WayButtonStyled = styled(Box)<BoxProps>(({ theme }) => ({
    position: "relative",
    padding: "12px 16px 18px 16px",
    borderRadius: "8px",
    height: "100%"
}));

export const WayButtonTitle = styled(Typography)<TypographyProps>(
    ({ theme }) => ({
        color: "#FFF",
        fontSize: ".9rem",
        fontWeight: 600,
        borderRadius: "5px"
    })
);

export const WayButtonDescription = styled(Typography)<TypographyProps>(
    ({ theme }) => ({
        marginTop: "4px",
        color: "#FFF",
        lineHeight: "136%",
        fontSize: ".8rem",
        fontWeight: 500,
        maxWidth: "280px"
    })
);

export const WayButtonActionArea = styled(CardActionArea)({
    borderRadius: "8px",
    height: "100%"
});

export const WayIcon = styled(LockRoundedIcon)({
    position: "absolute",
    fontSize: "1rem",
    color: "#FFF",
    right: 12,
    bottom: 12
});

export const SwiperContainer = styled(Box)({
    // width: "calc(100% + 20px)"
    width: "100%"
});

export const StyledLink = styled(Link)<LinkProps>({
    textDecoration: "none",
    color: "inherit"
});
