import type { FC } from "react";
import { Fragment } from "react";
import { useUserContext } from "context/UserContext";

// Components
import ToStartCard from "components/ToStartCard";
import CTitle from "components/CTitle";
import { ResendButton } from "styled-components/Buttons";
import { ToStartListContainer, ToStartListSwiperContainer } from "./styled";

import { START_TYPES_LIST, START_TYPES_CONFIG } from "constants/start";

// Swiper
// import Swiper core and required modules
import { Navigation, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "../swiper.css";

import { isBrowser } from "react-device-detect";
interface ToStartListProps {
    title: string;
    buttonText?: string;
    description?: string;
}

const GigsList: FC<ToStartListProps> = props => {
    const { buttonText } = props;
    const { userData } = useUserContext();

    /**
     * Returns Ids of gig types to fetch them later
     * @returns {START_TYPES_CONFIG[]} Array with gig types
     */
    const userAvailableList = (): {
        availableList: START_TYPES_CONFIG[];
    } => {
        let availableList: START_TYPES_CONFIG[] = [];

        // eslint-disable-next-line array-callback-return
        START_TYPES_LIST.map(type => {
            if (
                type._databaseId === "verify-your-email" &&
                !userData?.emailEnabled &&
                !userData.emailSentStatus
            ) {
                availableList.push(type);
            } else if (
                type._databaseId === "show-us-a-video" &&
                userData?.videoVerified === false
            ) {
                availableList.push(type);
            } else if (
                type._databaseId === "verify-your-account-with-id" &&
                userData?.stripeEnabled === false
            ) {
                availableList.push(type);
            } else if (
                type._databaseId === "zip-code" &&
                userData?.zipcode === null
            ) {
                availableList.push(type);
            }
        });
        return { availableList };
    };

    if (userAvailableList().availableList.length === 0) {
        return <></>;
    }

    return (
        <Fragment>
            <ToStartListContainer>
                <CTitle {...props} />
                {buttonText && (
                    <ResendButton sx={{ width: "auto" }}>
                        {buttonText}
                    </ResendButton>
                )}
            </ToStartListContainer>

            <ToStartListSwiperContainer>
                <Swiper
                    // install Swiper modules
                    modules={[Navigation, Scrollbar]}
                    navigation={isBrowser ? true : false}
                    scrollbar={isBrowser ? true : false}
                    slidesPerView={1.5}
                    spaceBetween={16}
                    lazy>
                    {userAvailableList().availableList.map(
                        (single, index: number) => (
                            <SwiperSlide key={index}>
                                <ToStartCard {...single} />
                            </SwiperSlide>
                        )
                    )}
                </Swiper>
            </ToStartListSwiperContainer>
        </Fragment>
    );
};

export default GigsList;
