import { getUserInfo } from 'api';
import reactGA from 'react-ga';
import mixpanel from 'mixpanel-browser';
import { useCallback } from 'react'
const useEventTracker = () => {
    const { id } = getUserInfo();

    const trackEvent = useCallback((name: string, category: string) => {
        mixpanel.identify(id);
        mixpanel.track(name);
        reactGA.event({
            category,
            action: name,
            label: id,
        });
    }, [id])

    return { trackEvent };
};

export default useEventTracker;
