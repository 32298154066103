import type { HIVEMIND_GIG_CONFIG } from "constants/gigs";
import type { FC } from "react";
import {
    WayButtonActionArea,
    WayButtonDescription,
    WayButtonStyled,
    WayButtonTitle,
    WayIcon
} from "./styled";
import { getQuestionsByFlowType } from "api";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "context/UserContext";
import Me from "api/Me";
import { useSnackbar } from "notistack";

const WayButton: FC<HIVEMIND_GIG_CONFIG> = ({
    name,
    shortDescription,
    color,
    _databaseId,
    emoji
}) => {
    const navigator = useNavigate();
    const { userData } = useUserContext();
    const { enqueueSnackbar } = useSnackbar();

    return (
        <WayButtonActionArea
            onClick={async () => {
                const questions = await getQuestionsByFlowType(_databaseId);

                if (!questions.length) {
                    await Me.addFlowType([...userData.flowType, _databaseId]);
                    await Me.addFlowTypeBasicCompleted([
                        ...userData.flowTypeBasicCompleted,
                        _databaseId
                    ]);
                    enqueueSnackbar(
                        "Congratulations, you unlocked a new way to earn",
                        { variant: "success", autoHideDuration: 2000 }
                    );
                    setTimeout(() => {
                        navigator("/");
                    }, 2000);
                } else {
                    navigator(
                        `/onboarding/basic/type/${_databaseId}?flowType=${_databaseId}`
                    );
                }
            }}>
            <WayButtonStyled bgcolor={color}>
                <WayButtonTitle>
                    {name} {emoji}
                </WayButtonTitle>
                <WayButtonDescription>
                    {shortDescription ||
                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates qui a nostrum magni fuga molestiae iste, alias sint quam quidem!"}
                </WayButtonDescription>
                <WayIcon />
            </WayButtonStyled>
        </WayButtonActionArea>
    );
};

export default WayButton;
