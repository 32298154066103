import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/material";

export const Root = styled("div")(({ theme }) => ({
    height: "100%",
    backgroundColor:
        theme.palette.mode === "light"
            ? grey[100]
            : theme.palette.background.default
}));

export const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#181818"
}));

export const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[400],
    borderRadius: 3,
    position: "absolute",
    top: 10,
    left: "calc(50% - 15px)"
}));
