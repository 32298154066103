import { Suspense } from "react";
import Navigation from "layouts/Navigation";
import Navbar from "layouts/Header";
import { Box, Container } from "@mui/material";
import { useUserContext } from "context/UserContext";
import { Navigate, Outlet } from "react-router-dom";
import { Loader } from "styled-components/Miscelaneous";

const MainLayout = () => {
    const { userData } = useUserContext();

    if (userData.answeredOnboarding === false)
        return (
            <Navigate
                to='/onboarding/how-do-you-want-to-make-extra-money'
                replace
            />
        );

    return (
        <Container
            component='main'
            maxWidth='xs'
            sx={{
                display: "flex",
                flexDirection: "column",
                padding: 0,
                height: "100vh"
            }}>
            <Navbar />
            <Suspense fallback={<Loader />}>
                <Box display='flex' flexDirection='column' flex={1}>
                    <Outlet />
                </Box>
            </Suspense>
            {userData.status !== "Outlogged" && <Navigation />}
        </Container>
    );
};

export default MainLayout;
