type OS = "other" | "mac" | "windows" | "linux" | "android" | "ios";

const getOs: () => OS = () => {
    const userAgent = navigator.userAgent;
    const platform = navigator.platform;

    if (/Win/i.test(platform)) return "windows";
    if (/Mac/i.test(platform)) return "mac";
    if (/Linux/i.test(platform)) return "linux";
    if (/Android/i.test(userAgent)) return "android";
    if (
        /iPhone/i.test(userAgent) ||
        /iPad/i.test(userAgent) ||
        /iPod/i.test(userAgent)
    )
        return "ios";

    return "other";
};

export default getOs;
