import type { FC } from "react";
import { CTitleDescription, CTitleFlexbox, CTitleH3 } from "./styled";

interface CTitleProps {
    title: string;
    description?: string;
}

const CTitle: FC<CTitleProps> = ({ title, description }) => {
    return (
        <CTitleFlexbox>
            <CTitleH3 variant='h3'>{title}</CTitleH3>
            <CTitleDescription>{description}</CTitleDescription>
        </CTitleFlexbox>
    );
};

export default CTitle;
