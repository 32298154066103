import { styled } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import Typography, { TypographyProps } from "@mui/material/Typography";

export const CTitleFlexbox = styled(Box)<BoxProps>(({ theme }) => ({
    display: "flex",
    flexDirection: "column"
}));

export const CTitleH3 = styled(Typography)<TypographyProps>(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: "1rem",
    letterSpacing: "-.32px"
}));

export const CTitleDescription = styled(Typography)<TypographyProps>(
    ({ theme }) => ({
        color: "#878787",
        fontSize: ".8rem",
        fontWeight: 500
    })
);
