import type { Gig } from "types/gigs";
import type { FC } from "react";
import { Fragment } from "react";

// Components
import GigCard from "components/GigCard";
import CTitle from "components/CTitle";
import { ResendButton } from "styled-components/Buttons";
import { GigListContainer, GigListSwiperContainer } from "./styled";

import Box from "@mui/material/Box";

import NoGigsBox from "./indexNoGigs";

import Typography from "@mui/material/Typography";

// Swiper
// import Swiper core and required modules
import { Navigation, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "../swiper.css";

import { isBrowser } from "react-device-detect";
import { useUserContext } from "context/UserContext";
import { GIG_TYPES } from "constants/gigs";

interface GigListProps {
    title: string;
    buttonText?: string;
    gigs: Gig[];
    description?: string;
    withFlowType: boolean;
}

const GigsList: FC<GigListProps> = props => {
    const { buttonText, gigs } = props;
    const { userData } = useUserContext();
    const latestGigs = gigs.filter((gig: Gig) => {
        if (gig.flowType === GIG_TYPES.COUNCIL_MEMBERS_EXPRESS) {
            return true;
        } else {
            return userData.flowTypeBasicCompleted.includes(gig.flowType);
        }
    });

    if (latestGigs?.length === 0)
        return (
            <NoGigsBox
                gigs={gigs}
                title={props.title}
                description={props.description}
                withFlowType={false}
            />
        );

    return (
        <Fragment>
            <GigListContainer>
                <CTitle {...props} />
                {buttonText && (
                    <ResendButton sx={{ width: "auto" }}>
                        {buttonText}
                    </ResendButton>
                )}
            </GigListContainer>
            <Typography
                sx={{
                    width: "100%",
                    textAlign: "left",
                    fontSize: "0.7rem",
                    color: "#cccccc",
                    marginTop: "4px"
                }}
                variant='body2'>
                {gigs?.length} gigs available
            </Typography>
            <GigListSwiperContainer>
                <Swiper
                    // install Swiper modules
                    modules={[Navigation, Scrollbar]}
                    navigation={isBrowser ? true : false}
                    scrollbar={isBrowser ? true : false}
                    slidesPerView={1.5}
                    spaceBetween={16}
                    lazy>
                    {latestGigs.map((gig: Gig, index: number) => (
                        <Box key={"box" + index}>
                            <SwiperSlide key={"swg" + index}>
                                <GigCard {...gig} />
                            </SwiperSlide>
                        </Box>
                    ))}
                </Swiper>
            </GigListSwiperContainer>
        </Fragment>
    );
};

export default GigsList;
