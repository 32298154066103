import type { FC } from "react";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import { BpCheckedIcon, BpIcon } from "./styled";

const CCheckbox: FC<CheckboxProps> = props => {
    return (
        <Checkbox
            {...props}
            sx={{
                ...props.sx,
                "&:hover": { bgcolor: "transparent" }
            }}
            disableRipple
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
        />
    );
};

export default CCheckbox;
