import type { FC } from "react";
import { Fragment } from "react";

// Hooks
import useHome from "./useHome";

import { Box, Divider, Fade } from "@mui/material";
import { AppBox } from "styled-components/Boxes";
import { Loader } from "styled-components/Miscelaneous";

import MoreWays from "components/MoreWays";
import GigsList from "components/GigsList";
import CDailyQuesion from "components/CDailyQuestion";

import AmbassadorsShareList from "components/AmbassadorsShareList";
import ToStartList from "components/ToStartList";

import Alert from "@mui/material/Alert";
import GigOnboardingCard from "components/GigTypeForOnboardingCard";
import { GIG_TYPES } from "constants/gigs";

const Homepage: FC<any> = () => {
    const {
        gigs,
        gigsForAmbassador,
        loading,
        needAdminApproval,
        userData,
        isFlowTypeCompleted,
        userPreference,
        finalizedStatus
    } = useHome();

    if (loading) return <Loader />;

    return (
        <Fade in={!loading}>
            <AppBox>
                {userData.emailEnabled === false &&
                userData.emailSentStatus === false ? (
                    <Alert
                        sx={{ width: "100%", marginBottom: "10px" }}
                        severity='warning'>
                        Hey, you should verify your email address.
                    </Alert>
                ) : null}

                {userData.emailSentStatus === true &&
                userData.emailEnabled === false ? (
                    <Alert
                        sx={{ width: "100%", marginBottom: "10px" }}
                        severity='warning'>
                        Please, check your email and click the link we sent to
                        verify your account and start using 10K Voices
                    </Alert>
                ) : null}

                <Box
                    width='100%'
                    component='section'
                    sx={{ marginTop: "10px" }}>
                    {finalizedStatus ? (
                        <>
                            <GigsList
                                gigs={gigs}
                                title='Latest Gigs just for You'
                                description='Gigs based on your preferences'
                                withFlowType={false}
                            />
                            <Divider sx={{ margin: "30px 0 26px 0" }} />
                        </>
                    ) : (
                        <>
                            {needAdminApproval ? (
                                <Alert
                                    sx={{ width: "100%", marginBottom: "10px" }}
                                    severity='success'>
                                    Good job! Your profile is now under review
                                    by our admin team.
                                </Alert>
                            ) : (
                                <>
                                    <ToStartList
                                        title='To Start'
                                        description='First steps in 10K Voices'
                                        buttonText=''
                                    />
                                    <Divider sx={{ margin: "30px 0 26px 0" }} />
                                </>
                            )}
                        </>
                    )}

                    <CDailyQuesion />

                    <MoreWays />

                    {userData.flowType?.includes("ambassadors") && (
                        <Fragment>
                            {isFlowTypeCompleted(GIG_TYPES.AMBASSADORS) &&
                            finalizedStatus ? (
                                <>
                                    <Divider sx={{ margin: "30px 0 26px 0" }} />
                                    <AmbassadorsShareList
                                        gigs={gigsForAmbassador}
                                        title='Networker'
                                        description='Find people for projects through your personal and professional networks.'
                                        withFlowType={true}
                                    />
                                </>
                            ) : (
                                <GigOnboardingCard
                                    flowType={GIG_TYPES.AMBASSADORS}
                                />
                            )}
                        </Fragment>
                    )}

                    {userPreference().preferencesSelectedOnboardingType.map(
                        (type, index) => {
                            const reviewerGigs = [...gigs].filter(gig =>
                                [
                                    GIG_TYPES.COUNCIL_MEMBERS,
                                    GIG_TYPES.COUNCIL_MEMBERS_EXPRESS
                                ].includes(gig.flowType)
                            );
                            const filteredGigs =
                                type._databaseId === GIG_TYPES.COUNCIL_MEMBERS
                                    ? reviewerGigs
                                    : gigs.filter(
                                          gig =>
                                              gig.flowType === type._databaseId
                                      );

                            return type._databaseId !== "ambassadors" ? (
                                <Fragment key={index}>
                                    {isFlowTypeCompleted(type._databaseId) &&
                                    finalizedStatus ? (
                                        <>
                                            <Divider
                                                sx={{ margin: "30px 0 26px 0" }}
                                            />
                                            <GigsList
                                                gigs={filteredGigs}
                                                title={type.name}
                                                description={
                                                    type.shortDescription
                                                }
                                                withFlowType={true}
                                            />
                                        </>
                                    ) : (
                                        <GigOnboardingCard
                                            flowType={type._databaseId}
                                        />
                                    )}
                                </Fragment>
                            ) : null;
                        }
                    )}

                    <Divider sx={{ margin: "30px 0 26px 0" }} />
                </Box>
            </AppBox>
        </Fade>
    );
};

export default Homepage;
