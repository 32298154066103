import { alpha, styled, TextField, TextFieldProps } from "@mui/material";

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
        padding: "0 4px",
        borderRadius: "8px",
        color: theme.palette.mode === "light" ? "#3E3E3E" : "#A7A7A7",
        fontWeight: 600,
        fontSize: "1rem",

        "&.Mui-focused fieldset": {
            borderWidth: "3px",
            borderColor:
                theme.palette.mode === "light"
                    ? theme.palette.primary.main
                    : alpha(theme.palette.primary.main, 0.4)
        },

        "& fieldset": {
            borderWidth: "2px"
        }
    }
}));

export default StyledTextField;
