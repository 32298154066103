import { alpha, styled } from "@mui/material/styles";
import Radio, { RadioProps } from "@mui/material/Radio";

const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: 5,
    maxHeight: "26px",
    minHeight: "26px",
    minWidth: "26px",
    maxWidth: "26px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "none",
    backgroundColor: theme.palette.mode === "light" ? "#EFEFEF" : "#202020",
    backgroundImage:
        theme.palette.mode === "light"
            ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
            : "background-image: linear-gradient(180deg,#202020,#202020)",
    ".Mui-focusVisible &": {
        outline: "2px auto rgba(19,124,189,.6)",
        outlineOffset: 2
    },
    "input:disabled ~ &": {
        boxShadow: "none",
        background: "rgba(57,75,89,.5)"
    },
    "&:before": {
        display: "block",
        width: 18,
        height: 18,
        backgroundImage:
            theme.palette.mode === "light"
                ? "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                  " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.26.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                  "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.26-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")"
                : "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                  " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.26.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                  "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.26-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23181818'/%3E%3C/svg%3E\")",
        content: '""'
    }
}));

const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
    maxHeight: "26px",
    minHeight: "26px",
    minWidth: "26px",
    maxWidth: "26px",
    backgroundColor:
        theme.palette.mode === "light" ? "#A060CA" : alpha("#A060CA", 0.2),
    backgroundImage:
        theme.palette.mode === "light"
            ? "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))"
            : "",
    "&:before": {
        display: "block",
        width: 18,
        height: 18,
        backgroundImage:
            theme.palette.mode === "light"
                ? "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                  " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.26.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                  "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.26-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")"
                : "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                  " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.26.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                  "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.26-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23A060CA'/%3E%3C/svg%3E\")",
        content: '""'
    }
}));

// Inspired by blueprintjs
export default function CRadioButton(props: RadioProps) {
    return (
        <Radio
            sx={{
                padding: "9px 0 9px 9px",
                "&:hover": {
                    bgcolor: "transparent"
                }
            }}
            disableRipple
            color='default'
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            {...props}
        />
    );
}
