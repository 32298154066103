// React & Router
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Context
import { useGigsContext } from "context/GigsContext";
import { useUserContext } from "context/UserContext";

// Api endpoints
import Gigs from "api/Gigs";
import { GIG_TYPES, HIVEMIND_GIGS, HIVEMIND_GIG_CONFIG } from "constants/gigs";
import { GetMe, getUserInfo, GetGigsByUser, GetAllAmbassadorsGigs } from "api";
import UserSettings from "api/UserSettings";

import { useSnackbar } from "notistack";
import { USER_STATUS } from "types/user";
import { Gig } from "types/gigs";

const useHome = () => {
    const navigate = useNavigate();
    const { gigs, setGigs } = useGigsContext();
    const { gigsForAmbassador, setGigsForAmbassador } = useGigsContext();
    const [loading, setLoading] = useState(gigs.length === 0 ? true : false);
    const { token, id } = getUserInfo();
    const { userData, setUserData } = useUserContext();
    const { enqueueSnackbar } = useSnackbar();

    const isVerified =
        userData.emailEnabled &&
        userData.zipcode &&
        userData.stripeEnabled &&
        userData.videoVerified;

    const finalizedStatus =
        isVerified && userData.status === USER_STATUS.ENABLED;

    const needAdminApproval =
        isVerified && userData.status === USER_STATUS.PENDING;

    useEffect(() => {
        setLoading(true);

        // get user info
        Promise.all([
            GetMe(token),
            UserSettings.get(id),
            GetGigsByUser(),
            Gigs.getAllProgress(userData.id),
            GetAllAmbassadorsGigs()
        ])
            .then(([me, userSetting, gigs, progress, resAmbassadors]) => {
                setUserData({
                    ...me.data.user,
                    settings: userSetting.data
                });

                // eslint-disable-next-line array-callback-return
                progress.data?.some((gig: any) => {
                    if (gig.rate && !gig.gigRate && !gig.rejected) {
                        return navigate(`/progress/gig/${gig.id}`);
                    }
                });
                const filteredGigs = gigs.data.gigs.filter(
                    (e: Gig) => e.flowType !== "ambassadors"
                );

                setGigs(filteredGigs.concat(resAmbassadors.data.gigs));

                // eslint-disable-next-line array-callback-return
                setGigsForAmbassador(resAmbassadors.data.gigs);
            })
            .catch(error => {
                enqueueSnackbar(error, {
                    variant: "error"
                });
            })
            .finally(() => {
                setLoading(false);
            });

        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isFlowTypeCompleted = (flowType: GIG_TYPES) => {
        return userData.flowTypeBasicCompleted.includes(flowType);
    };

    /**
     * Returns Ids of gig types to fetch them later
     * @returns {HIVEMIND_GIG_CONFIG[]} Array with gig types
     */
    const userPreference = (): {
        notSelectedOnboardingType: HIVEMIND_GIG_CONFIG[];
        preferencesSelectedOnboardingType: HIVEMIND_GIG_CONFIG[];
    } => {
        let notSelectedOnboardingType: HIVEMIND_GIG_CONFIG[] = [];
        let preferencesSelectedOnboardingType: HIVEMIND_GIG_CONFIG[] = [];

        // eslint-disable-next-line array-callback-return
        HIVEMIND_GIGS.map(type => {
            if (!userData.flowType.includes(type._databaseId)) {
                notSelectedOnboardingType.push(type);
            } else {
                preferencesSelectedOnboardingType.push(type);
            }
        });
        return {
            notSelectedOnboardingType,
            preferencesSelectedOnboardingType
        };
    };

    return {
        userData,
        loading,
        gigs,
        gigsForAmbassador,
        needAdminApproval,
        userPreference,
        isFlowTypeCompleted,
        finalizedStatus
    };
};
export default useHome;
