// Axios
import axios from "axios";

import type { ReferralUsername, User } from "types/user";

import { getUserInfo } from "api";
import getEnvVariable from "env";
import { AddPaymentAccountData, PaymentAccount } from "types/user/settings";
import { Question } from "types/question";

const apiUrl = getEnvVariable("API_URL");
const { token } = getUserInfo(); // Token

// Axios instance
const instance = axios.create({
    baseURL: apiUrl,
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
    }
});

/**
 * @desc Update user settings
 * @param {string} email Data that includes all the settings and user ID
 * @return {Promise<{ data: null; error: any;} | { data: string; error: null; }>} User settings or error
 */
const sendLoginEmail = async (
    email: string,
    token: string
): Promise<{ data: null; error: any } | { data: string; error: null }> => {
    try {
        const response = await instance.post(`/api/me/sendLoginEmail`, {
            email,
            token
        });
        if (response.status !== 200)
            return { data: null, error: response.data.message };
        return {
            data: response.data.data.userPhoneNumber,
            error: null
        };
    } catch (err: any) {
        return {
            data: null,
            error:
                err.response.data.message ||
                err.message ||
                "Something went wrong "
        };
    }
};

const sendChatRequest = async (params: {
    userId: string;
    title: string;
}): Promise<{ data: null; error: any } | { data: string; error: null }> => {
    try {
        const response = await instance.post(`/api/me/chat-request`, params);
        if (response.status !== 200)
            return { data: null, error: response.data.message };
        return {
            data: response.data.data,
            error: null
        };
    } catch (err: any) {
        return {
            data: null,
            error:
                err.response.data.message ||
                err.message ||
                "Something went wrong "
        };
    }
};
/**
 * @desc Update user settings
 * @param {string} id Data that includes all the settings and user ID
 * @return {Promise<{ data: null; error: any;} | { data: ReferralUsername; error: null; }>} User settings or error
 */
const getUserName = async (
    id: string
): Promise<
    { data: null; error: any } | { data: ReferralUsername; error: null }
> => {
    try {
        const response = await instance.post(`/api/me/getUserNameById`, {
            userId: id
        });
        if (response.status !== 200)
            return { data: null, error: response.data.message };
        return {
            data: response.data.data.user,
            error: null
        };
    } catch (err: any) {
        return {
            data: null,
            error:
                err.response.data.message ||
                err.message ||
                "Something went wrong "
        };
    }
};

/**
 * @desc Add an payment account to the user settings model
 * @param {AddPaymentAccountData} data Data that includes account email, account type & userId
 * @return {Promise<{ data: null; error: any;} | { data: PaymentAccount; error: null; }>} User settings or error
 */
const addPaymentAccount = async (
    data: AddPaymentAccountData
): Promise<
    { data: null; error: any } | { data: PaymentAccount; error: null }
> => {
    const { token } = getUserInfo();

    try {
        const response = await instance.post(`api/me/addPaymentAccount`, data, {
            headers: { Authorization: `Bearer ${token}` }
        });
        if (response.status !== 200)
            return { data: null, error: response.data.message };
        return {
            data: response.data.data.paymentAccount,
            error: null
        };
    } catch (err: any) {
        return {
            data: null,
            error:
                err.response.data.message ||
                err.message ||
                "Something went wrong "
        };
    }
};

/**
 * @desc Get all unanswered questions
 * @return {Promise<{ data: null; error: any;} | { data: Question[]; error: null; }>} Array of unanswered questions
 */
const getUnansweredQuestions = async (
    flowType: string[]
): Promise<{ data: null; error: any } | { data: Question[]; error: null }> => {
    const { token } = getUserInfo();

    try {
        const response = await instance.post(
            `api/me/unansweredQuestions`,
            {
                flowType
            },
            {
                headers: { Authorization: `Bearer ${token}` }
            }
        );
        if (response.status !== 200)
            return { data: null, error: response.data.message };
        return {
            data: response.data.data.questions,
            error: null
        };
    } catch (err: any) {
        return {
            data: null,
            error:
                err.response.data.message ||
                err.message ||
                "Something went wrong "
        };
    }
};

/**
 * @desc remove payment account to the user settings model
 * @param {string} id Data that includes account email, account type & userId
 * @return {Promise<{ data: null; error: any;} | { data: string; error: null; }>} User settings or error
 */
const removePaymentAccount = async (
    data: any
): Promise<{ data: null; error: any } | { data: any; error: null }> => {
    try {
        const response = await instance.post(
            `api/me/removePaymentAccount`,
            data
        );
        if (response.status !== 200)
            return { data: null, error: response.data.message };
        return {
            data: response.data.data.data,
            error: null
        };
    } catch (err: any) {
        return {
            data: null,
            error:
                err.response.data.message ||
                err.message ||
                "Something went wrong "
        };
    }
};

/**
 * @desc Add flow type after four steps onboarding
 * @param {string} id Data that includes account email, account type & userId
 * @return {Promise<{ data: null; error: any;} | { data: User; error: null; }>} User settings or error
 */
const addFlowType = async (
    data: string[]
): Promise<{ data: null; error: any } | { data: User; error: null }> => {
    const { token } = getUserInfo();

    try {
        const response = await instance.put(
            `api/me/update`,
            { flowType: data },
            { headers: { Authorization: `Bearer ${token}` } }
        );
        if (response.status !== 200)
            return { data: null, error: response.data.message };
        return {
            data: response.data.data.data,
            error: null
        };
    } catch (err: any) {
        return {
            data: null,
            error:
                err.response.data.message ||
                err.message ||
                "Something went wrong "
        };
    }
};

/**
 * @desc add flow type after complete each basic
 * @param {string} id Data that includes account email, account type & userId
 * @return {Promise<{ data: null; error: any;} | { data: User; error: null; }>} User settings or error
 */
const addFlowTypeBasicCompleted = async (
    data: string[]
): Promise<{ data: null; error: any } | { data: User; error: null }> => {
    const { token } = getUserInfo();

    try {
        const response = await instance.put(
            `api/me/update`,
            { flowTypeBasicCompleted: data },
            { headers: { Authorization: `Bearer ${token}` } }
        );
        if (response.status !== 200)
            return { data: null, error: response.data.message };
        return {
            data: response.data.data.data,
            error: null
        };
    } catch (err: any) {
        return {
            data: null,
            error:
                err.response.data.message ||
                err.message ||
                "Something went wrong "
        };
    }
};

/**
 * @desc add answered onboarding after finnish four steps onboarding
 * @param {string} id Data that includes account email, account type & userId
 * @return {Promise<{ data: null; error: any;} | { data: User; error: null; }>} User settings or error
 */
const addAnsweredOnboarding = async (
    data: string[]
): Promise<{ data: null; error: any } | { data: User; error: null }> => {
    const { token } = getUserInfo();

    try {
        const response = await instance.put(
            `api/me/update`,
            { answeredOnboarding: data },
            { headers: { Authorization: `Bearer ${token}` } }
        );
        if (response.status !== 200)
            return { data: null, error: response.data.message };
        return {
            data: response.data.data.data,
            error: null
        };
    } catch (err: any) {
        return {
            data: null,
            error:
                err.response.data.message ||
                err.message ||
                "Something went wrong "
        };
    }
};

/**
 * @desc add answered onboarding general after finish To star Tell about us
 * @param {string} id Data that includes account email, account type & userId
 * @return {Promise<{ data: null; error: any;} | { data: User; error: null; }>} User settings or error
 */
const addAnsweredOnboardingGeneral = async (
    data: string[]
): Promise<{ data: null; error: any } | { data: User; error: null }> => {
    const { token } = getUserInfo();

    try {
        const response = await instance.put(
            `api/me/update`,
            { answeredOnboardingGeneral: data },
            { headers: { Authorization: `Bearer ${token}` } }
        );
        if (response.status !== 200)
            return { data: null, error: response.data.message };
        return {
            data: response.data.data.data,
            error: null
        };
    } catch (err: any) {
        return {
            data: null,
            error:
                err.response.data.message ||
                err.message ||
                "Something went wrong "
        };
    }
};

/**
 * @desc add email sended when user send a email but its not verify yet
 * @param {string} id Data that includes account email, account type & userId
 * @return {Promise<{ data: null; error: any;} | { data: User; error: null; }>} User settings or error
 */
const addEmailSendedStatus = async (
    data: string[]
): Promise<{ data: null; error: any } | { data: User; error: null }> => {
    const { token } = getUserInfo();

    try {
        const response = await instance.put(
            `api/me/update`,
            { emailSentStatus: data },
            { headers: { Authorization: `Bearer ${token}` } }
        );
        if (response.status !== 200)
            return { data: null, error: response.data.message };
        return {
            data: response.data.data.data,
            error: null
        };
    } catch (err: any) {
        return {
            data: null,
            error:
                err.response.data.message ||
                err.message ||
                "Something went wrong "
        };
    }
};

const Me = {
    sendLoginEmail: (email: string, token: string) =>
        sendLoginEmail(email, token),
    getUserName: (id: string) => getUserName(id),
    addAnsweredOnboarding: (data: any) => addAnsweredOnboarding(data),
    addAnsweredOnboardingGeneral: (data: any) =>
        addAnsweredOnboardingGeneral(data),
    addEmailSendedStatus: (data: any) => addEmailSendedStatus(data),
    addPaymentAccount: (data: AddPaymentAccountData) => addPaymentAccount(data),
    removePaymentAccount: (data: any) => removePaymentAccount(data),
    addFlowType: (data: any) => addFlowType(data),
    addFlowTypeBasicCompleted: (data: any) => addFlowTypeBasicCompleted(data),
    getUnansweredQuestions: (flowType: string[]) =>
        getUnansweredQuestions(flowType),
    sendChatRequest
};

export default Me;
