import type { Question as QuestionType } from "types/question";
import { Fragment } from "react";
import useCDailyQuesion from "./useCDailyQuestion";

import { Global } from "@emotion/react";
import { Puller } from "pages/Gig/Components/ShareModal/styled";
import { CustomButton } from "styled-components/Buttons";
import { Box, Grow, SwipeableDrawer } from "@mui/material";
import Question from "pages/Onboarding/Screens/Question/v2";
import {
    QuestionOfTheDay,
    QuestionOfTheDayActionArea,
    QuestionOfTheDayContainer,
    QuestionOfTheDayIcon
} from "./styled";

import CTitle from "components/CTitle";
// Loading Skeleton & Error
import DailyQuestionSkeleton from "./Skeleton";
import NoQuestionsCard from "./NoQuestionsCard";
// import NoFlowTypeCompletedNoQuestionsCard from "./NoFlowTypeCompletedNoQuestionsCard";

// #TODO: Make daily question dynamic
const CDailyQuesion = () => {
    const {
        loading,
        questionOfDay,
        openQuestionOfDayModal,
        setOpenQuestionOfDayModal,
        handleAnswerQuestionOfDay
        // userData
    } = useCDailyQuesion();

    // if (userData.flowTypeBasicCompleted?.length === 0)
    //     return <NoFlowTypeCompletedNoQuestionsCard />;
    if (loading) return <DailyQuestionSkeleton />;
    if (!loading && (!questionOfDay || !questionOfDay.id))
        return <NoQuestionsCard />;

    // For drawer
    const container = window !== undefined ? document.body : undefined;

    return (
        <Fragment>
            <Grow in={true}>
                <div>
                    <CTitle
                        title='Questions of the day'
                        description='Find the right Gigs for you'
                    />
                    <QuestionOfTheDayActionArea
                        onClick={() => setOpenQuestionOfDayModal(true)}>
                        <QuestionOfTheDayContainer>
                            <QuestionOfTheDay>
                                {questionOfDay?.value}
                            </QuestionOfTheDay>
                            <QuestionOfTheDayIcon />
                        </QuestionOfTheDayContainer>
                    </QuestionOfTheDayActionArea>
                    <CustomButton
                        sx={{ maxWidth: "180px" }}
                        onClick={() => setOpenQuestionOfDayModal(true)}>
                        Answer now
                    </CustomButton>
                </div>
            </Grow>
            <Global
                styles={{
                    ".MuiDrawer-root > .MuiPaper-root": {
                        height: "84vh",
                        borderRadius: "20px 20px 0 0",
                        maxWidth: "444px",
                        margin: "auto",
                        backgroundImage: "none"
                    }
                }}
            />
            <SwipeableDrawer
                container={container}
                anchor='bottom'
                open={openQuestionOfDayModal}
                onClose={() => setOpenQuestionOfDayModal(false)}
                onOpen={() => setOpenQuestionOfDayModal(true)}
                swipeAreaWidth={56}
                disableSwipeToOpen={true}>
                <Puller />
                <Box my={4} p={3}>
                    <Question
                        isVisible={true}
                        question={questionOfDay as QuestionType}
                        nextQuestion={handleAnswerQuestionOfDay}
                    />
                </Box>
            </SwipeableDrawer>
        </Fragment>
    );
};

export default CDailyQuesion;
