import { styled } from "@mui/material/styles";
import { Box, SkeletonProps, Typography } from "@mui/material";
import { LazyLoadImageProps } from "react-lazy-load-image-component";

export const CardBox = styled(Box)(({ theme }) => ({
    borderRadius: "10px",
    width: "100%",
    height: "180px",
    position: "relative"
}));

export const CardName = styled(Typography)(({ theme }) => ({
    position: "absolute",
    left: 14,
    bottom: 10,
    color: "#FFF",
    fontWeight: 600,
    letterSpacing: "-.36px",
    lineHeight: "136%",
    zIndex: 4,
    textAlign: "left",
    maxWidth: "192px"
}));

export const CardImageBackground = styled(Box)(({ theme }) => ({
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 2,
    top: 0,
    borderRadius: "10px",
    background:
        "linear-gradient(180deg, rgb(0 0 0 / 0%) 22.51%, rgb(0 0 0 / 40%) 73.56%)"
}));

export const ImageProps: LazyLoadImageProps = {
    width: "100%",
    height: "100%",
    effect: "blur",
    style: {
        objectFit: "cover",
        borderRadius: "10px",
        zIndex: 1
    }
};

export const LoaderProps: SkeletonProps = {
    variant: "rectangular",
    animation: "wave",
    width: "100%",
    height: "180px",
    sx: {
        borderRadius: "10px",
        position: "absolute",
        left: 0,
        top: 0
    }
};
