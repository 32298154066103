import { Fragment } from "react";
import { CSkeleton } from "pages/Onboarding/Screens/Question/skeleton";

const DailyQuestionSkeleton = () => (
    <Fragment>
        <CSkeleton
            variant='rectangular'
            width={160}
            height={21}
            animation='wave'
            sx={{ mb: 0.5 }}
        />
        <CSkeleton
            variant='rectangular'
            width={200}
            height={17}
            animation='wave'
        />
        <CSkeleton
            variant='rectangular'
            width='100%'
            height={62}
            animation='wave'
            sx={{ my: "12px" }}
        />
        <CSkeleton
            variant='rectangular'
            width={180}
            height={38}
            animation='wave'
            mt={3}
        />
    </Fragment>
);

export default DailyQuestionSkeleton;
