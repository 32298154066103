import Me from "api/Me";
import useEventTracker from "hooks/useEventTracker";
import { useSnackbar } from "notistack";
import { useMemo, useState } from "react";
import type { Question as QuestionType } from "types/question";

const useCDailyQuesion = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { trackEvent } = useEventTracker();

    const [openQuestionOfDayModal, setOpenQuestionOfDayModal] = useState<boolean>(false);
    const [questionOfDay, setQuestionOfDay] = useState<QuestionType | null>(
        null
    );

    const [loading, setLoading] = useState<boolean>(false);
    const [refetch, setRefetch] = useState<boolean>(false);

    useMemo(async () => {
        setLoading(true);

        const res = await Me.getUnansweredQuestions(["question-of-day"]);

        if (res.error) {
            enqueueSnackbar(res.error, {
                variant: "error"
            });
            return setLoading(false);
        }

        setQuestionOfDay(res.data ? res.data[0] : null);

        return setLoading(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetch]);

    const handleAnswerQuestionOfDay = async () => {
        enqueueSnackbar("Successfully answered ", { variant: "success" });
        setOpenQuestionOfDayModal(false);
        trackEvent('Question answered', 'Question of the day');
        setRefetch(!refetch);
    };

    return {
        loading,
        questionOfDay,
        openQuestionOfDayModal,
        setOpenQuestionOfDayModal,
        handleAnswerQuestionOfDay
    };
};

export default useCDailyQuesion;
